import { Component } from '@angular/core';
import {
    OrderDetailFragment,
    OrderHistoryEntryComponent,
    TimelineDisplayType,
    TimelineHistoryEntry,
    SharedModule,
} from '@vendure/admin-ui/core';

@Component({
    selector: 'stripe-subscription-notification-component',
    standalone: true,
    imports: [SharedModule],
    template: `
        <span>{{ entry.data.message }}</span>
        <a
            *ngIf="entry.data.merchoneId"
            [href]="'https://dashboard.merchone.com/orders/' + entry.data.merchoneId"
            class="btn btn-link btn-sm details-button"
            target="_blank"
        >
            <clr-icon shape="export" size="12"></clr-icon>
        </a>
        <br />
        <vdr-history-entry-detail *ngIf="entry.data.error" title="Error">
            <span>Error</span>
            <vdr-object-tree [value]="entry.data.error"></vdr-object-tree>
        </vdr-history-entry-detail>
        <vdr-history-entry-detail *ngIf="entry.data.response" title="Response">
            <span>Response</span>
            <vdr-object-tree [value]="entry.data.response"></vdr-object-tree>
        </vdr-history-entry-detail>
    `,
})
export class HistoryEntryComponent implements OrderHistoryEntryComponent {
    entry: TimelineHistoryEntry;
    order: OrderDetailFragment;

    constructor() {}

    getDisplayType(entry: TimelineHistoryEntry): TimelineDisplayType {
        return entry.data.valid ? 'success' : 'error';
    }

    isFeatured(entry: TimelineHistoryEntry): boolean {
        return !entry.data.valid;
    }

    getName(_entry: TimelineHistoryEntry): string {
        return 'Merchone Order';
    }

    getIconShape(entry: TimelineHistoryEntry) {
        return entry.data.valid ? 'shopping-cart' : 'exclamation-circle';
    }
}
