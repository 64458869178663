import { addActionBarItem, registerHistoryEntryComponent, JobQueueService } from '@vendure/admin-ui/core';
import { JobState } from './generated-types';
import { PlaceOrderInMerchoneDocument } from './generated-types';
import { HistoryEntryComponent } from './history-entry.component';
import gql from 'graphql-tag';
export default [
    registerHistoryEntryComponent({ type: 'MERCHONE_ORDER_HISTORY', component: HistoryEntryComponent }),
    addActionBarItem({
        id: 'merchone',
        label: 'Place in Merchone',
        icon: 'shopping-cart',
        locationId: 'order-detail',
        onClick: (_ev, { injector, dataService, notificationService, route }) => {
            const id = route.snapshot.paramMap.get('id');
            if (!id) {
                notificationService.error('Order id not found');
                return;
            }
            const jobQueueService = injector.get(JobQueueService);
            notificationService.info('Placing order in merchone');

            dataService.mutate(PlaceOrderInMerchoneDocument, { id }).subscribe(({ placeOrderInMerchone }) => {
                if (!placeOrderInMerchone) return;
                jobQueueService.addJob(placeOrderInMerchone.id.toString(), job => {
                    if (job.state === JobState.COMPLETED) {
                        const time = new Intl.NumberFormat().format(job.duration || 0);
                        notificationService.success('Order Placed to merchone', {
                            count: job.result.indexedItemCount,
                            time,
                        });
                    } else {
                        notificationService.error('Failed to place order to merchone');
                    }
                });
            });
        },
    }),

    addActionBarItem({
        id: 'merchone-migrate',
        label: 'Merchone Products Migration',
        locationId: 'product-list',
        onClick: (_, { dataService }) => {
            dataService
                .mutate(gql`
                    mutation {
                        addAllProductsFromMerchone
                    }
                `)
                .subscribe(() => {
                    console.log('done');
                });
        },
    }),
];
