import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesNavModule } from './extensions/9cb418646ae11015133ed40176f8be2074e2761be3546d87ad600d714033d915/invoices-nav.module';

import SharedProviders_1_0 from './extensions/merchone/providers';


@NgModule({
    imports: [CommonModule, InvoicesNavModule],
    providers: [...SharedProviders_1_0],
})
export class SharedExtensionsModule {}
